<template>
  <div>
    <v-dialog v-model="dialog" width="1200" persistent>
      <v-card class="rounded-lg">
        <template>
          <v-container fluid class="pt-2 pb-4" style="height: 280px">
            <v-row
              v-show="startLoadingAudio"
              style="display: flex; justify-content: center"
            >
              <v-col
                class="pt-4 mt-8"
                cols="12"
                style="display: flex; justify-content: center"
              >
                <span>{{ "Loading audio file...." }}</span>
              </v-col>
              <v-col
                class="pt-0 pl-10 pr-10"
                cols="12"
                style="display: flex; justify-content: center"
              >
                <v-progress-linear
                  class="ml-3 mr-3"
                  v-model="power"
                  color="#4FB14E"
                  height="10"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row v-show="showMp3Player" no-gutters class="mb-2">
              <v-col
                class="py-0 pr-1"
                style="display: flex; justify-content: end"
              >
                <v-btn
                  icon
                  @click="closeSoundDialog"
                  color="#424242"
                  style="
                    border-radius: 8px !important;
                    width: 30px !important;
                    height: 30px !important;
                  "
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="d-flex" no-gutters>
              <v-col
                cols="12"
                lg="8"
                md="8"
                sm="12"
                class="border pl-2"
                v-show="showMp3Player"
              >
                <v-row>
                  <v-col cols="12" md="8" lg="8" sm="12" class="py-0">
                    <p class="mb-0 audioTitle-font">
                      {{ cutText() }}
                      <v-icon
                        :color="Addfav == true ? '#FF6060' : ''"
                        @click="toggleCheck"
                        class="heart-icon"
                        >{{ heartIcon }}</v-icon
                      >
                    </p>

                    <p
                      class="mb-0"
                      style="font-size: 18px; color: #424242; opacity: 0.6"
                    >
                      {{ audioDescription }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    lg="4"
                    sm="12"
                    class="py-0"
                    style="text-align: end !important"
                  >
                    <v-btn class="play-back-btn" :disabled="disablePrevious">
                      <v-icon
                        @click="playPreviousSound()"
                        class="mr-2"
                        style="font-size: 32px; background-color: transparent"
                        color="#A6CC39"
                        >mdi-skip-previous</v-icon
                      >
                    </v-btn>
                    <v-icon
                      v-if="!showPlay"
                      @click="pauseAudio"
                      class="mr-2"
                      style="font-size: 60px"
                      color="#A6CC39"
                      >mdi-pause-circle</v-icon
                    >

                    <v-icon
                      v-if="showPlay"
                      @click="playAudio"
                      class="mr-2"
                      style="font-size: 60px"
                      color="#A6CC39"
                      >mdi-play-circle</v-icon
                    >
                    <v-btn class="play-next-btn" :disabled="disableNext">
                      <v-icon
                        @click="playNextSound()"
                        class="mr-2"
                        style="font-size: 32px"
                        color="#A6CC39"
                        >mdi-skip-next</v-icon
                      >
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters> </v-row>
                <v-row>
                  <v-col>
                    <div id="waveform"></div>
                    <v-row>
                      <v-col>
                        <span>{{ currentAudioTime }}</span>
                      </v-col>
                      <v-col
                        style="
                          align-items: flex-end;
                          display: flex;
                          justify-content: end;
                        "
                      >
                        <span class="mr-4">{{ audioDuration }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="ml-1">
                      <div
                        v-if="showAlertMessage"
                        style="color: red; font-weight: bold"
                      >
                        {{ $t("soundfilenotrewinded") }}
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-show="showMp3Player"
                class="border"
                cols="12"
                lg="4"
                md="4"
                sm="12"
                style="
                  display: flex;
                  padding: 0px 7px 5px 20px;
                  justify-content: center;
                "
              >
                <v-img :src="audioImg" class="audioPhoto"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template>
          <v-card v-show="showMp3Player" width="100%">
            <div class="scrollbar-container">
              <div class="video-scrollbar">
                <v-card-title class="pt-2 pb-2 videoTitle-font">
                  {{ audioName }}
                </v-card-title>

                <v-row no-gutters class="">
                  <v-col
                    cols="12"
                    class="pl-3"
                    style="
                      display: flex;
                      justify-content: center;
                      vertical-align: middle;
                    "
                  >
                    <v-avatar size="45" class="marginRight-6">
                      <v-img
                        v-if="profileImg === null"
                        src="@/assets/defaultprofile.png"
                      ></v-img>
                      <v-img v-else :src="profileImg"></v-img>
                    </v-avatar>
                    <v-text-field
                      v-model="new_comment"
                      :label="$t('writeacomment')"
                      outlined
                      @input="checkCommentLength(new_comment, 1)"
                      dense
                      class="mt-1 comment-textbox"
                      hide-details="auto"
                      maxlength="100"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-3"
                    style="display: flex; justify-content: end"
                  >
                    <v-btn
                      depressed
                      text
                      outlined
                      class="text-capitalize ml-5"
                      style="border-radius: 8px; font-size: 14px; width: 80px; height: 35px;margin-left: 5px;"
                      @click="(new_comment = ''), (mainComNull = true)"
                    >
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                       depressed
                      :disabled="mainComNull"
                      class="text-capitalize btn_hover_effect ml-5"
                      style="border-radius: 8px; font-size: 14px; width: 80px; height: 35px; color: #ffff;"
                      color="#A6CC39"
                      @click="AddNewComment()"
                    >
                      {{ $t("send") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <div class="comment-paddingLeft4">
                  <p class="mb-0" style="font-weight: bold; color: black">
                    {{ commentItems.length != 0 ? commentItems.length : "0" }}
                    {{ $t("comments") }}
                  </p>
                </div>
                <div
                  class="comment-paddingLeft4 pt-3"
                  v-for="(comment, index) in commentItems"
                  :key="index"
                >
                  <v-row no-gutters>
                    <!-- outer comment part -->
                    <v-col cols="11" md="11" style="display: flex">
                      <v-row no-gutters>
                        <v-col
                          cols="11"
                          style="display: flex; flex-direction: row"
                        >
                          <v-avatar
                            justify="center"
                            align="center"
                            size="35"
                            class="marginRight-6"
                          >
                            <v-img
                              v-if="comment.profileImage"
                              :src="comment.profileImage"
                            ></v-img>
                            <v-img
                              v-else
                              src="@/assets/defaultprofile.png"
                            ></v-img>
                          </v-avatar>
                          <!-- show comment/show edit text-box -->
                          <div class="d-flex flex-column" style="width: 100%">
                            <div v-if="!comment.editCommentFlag">
                              <p class="mb-1">
                                {{ comment.fullname }}
                                {{ comment.commentedTime }}
                                <br />
                                {{ comment.commentMessage }}
                              </p>
                            </div>
                            <!-- when edit comment show this -->
                            <div v-else>
                              <v-row no-gutters>
                                <v-col cols="12" class="d-flex">
                                  <v-text-field
                                    :placeholder="$t('writeacomment')"
                                    dense
                                    v-model="comment.tempComment"
                                    @input="
                                      checkCommentLength(comment.tempComment, 2)
                                    "
                                    hide-details="auto"
                                    maxlength="100"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row class="mt-0">
                                <v-col
                                  style="
                                    display: flex;
                                    justify-content: end;
                                    align-items: center;
                                  "
                                >
                                  <v-btn
                                    depressed
                                    text
                                    outlined
                                    class="text-capitalize"
                                    style="border-radius: 8px; font-size: 14px; width: 80px; height: 35px;"
                                    @click="cancelComment(comment)"
                                  >
                                    {{ $t("cancel") }}
                                  </v-btn>
                                  <v-btn
                                     depressed
                                    :disabled="commentNull"
                                    class="text-capitalize btn_hover_effect ml-5"
                                    style="border-radius: 8px; font-size: 14px; width: 80px; height: 35px; color: #ffff;
                                    padding: 0.3rem 0px 0.3rem 0px;"
                                    color="#A6CC39"
                                    @click="UpdateComment(comment)"
                                  >
                                    {{ $t("update") }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </div>
                            <!-- reply btn under each comment -->
                            <div class="pt-1">
                              <v-btn
                                style="
                                  font-size: 14px;
                                  min-width: 54px;
                                  min-height: 22px;
                                  height: auto !important;
                                  justify-content: center;
                                  background-color: #f0f0f0;
                                  opacity: 0.42;
                                  color: #424242;
                                  opacity: 1;
                                  border-radius: 4px;
                                  padding: 0;
                                "
                                text
                                class="text-capitalize"
                                @click="ClickCommentReply(comment)"
                              >
                                {{ $t("reply") }}
                              </v-btn>
                            </div>
                            <!-- show replies count text under reply btn -->
                            <div
                              v-if="comment.replyCommentLists.length != 0"
                              @click="showReplylist(comment)"
                            >
                              <p
                                style="
                                  color: blue;
                                  cursor: pointer;
                                  font-size: 14px;
                                  text-transform: lowercase;
                                "
                                class="mb-1"
                              >
                                <v-icon
                                  style="color: blue"
                                  v-if="comment.replyFlag"
                                  >mdi-chevron-down</v-icon
                                >
                                <v-icon v-else style="color: blue"
                                  >mdi-chevron-up</v-icon
                                >{{ comment.replyCommentLists.length }}
                                {{
                                  comment.replyCommentLists.length > 1
                                    ? $t("replies")
                                    : $t("reply")
                                }}
                              </p>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- dot actions for editing comments -->
                    <v-col
                      v-if="
                        comment.memberID == loginmemberID ||
                        loginmemberID == memberID
                      "
                      cols="1"
                      md="1"
                      class="px-0"
                      style="display: flex; justify-content: end"
                    >
                      <template>
                        <div class="d-flex" style="margin-top: -5px">
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                text
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  min-width: 32px !important;
                                  padding: 0px !important;
                                "
                              >
                                <v-icon style="color: #424242"
                                  >mdi-dots-vertical</v-icon
                                >
                              </v-btn>
                            </template>

                            <v-list class="d-flex flex-column align-start">
                              <div>
                                <v-list-item
                                  class="list-item-padding"
                                  v-show="loginmemberID == comment.memberID"
                                >
                                  <v-btn
                                    color="#424242"
                                    style="width: 100px; justify-content: left"
                                    text
                                    class="text-capitalize"
                                    @click="editComment(comment)"
                                  >
                                    <v-icon left
                                      >mdi-square-edit-outline</v-icon
                                    >
                                    {{ $t("edit") }}
                                  </v-btn>
                                </v-list-item>
                                <v-list-item class="list-item-padding">
                                  <v-btn
                                    color="#FF6060"
                                    style="width: 100px; justify-content: left"
                                    text
                                    class="text-capitalize"
                                    @click="prepareDeleteComment(comment)"
                                  >
                                    <v-icon left>mdi-delete-outline</v-icon>
                                    {{ $t("delete") }}
                                  </v-btn>
                                </v-list-item>
                              </div>
                            </v-list>
                          </v-menu>
                        </div>
                      </template>
                    </v-col>
                  </v-row>
                  <!-- reply text box to outer comment -->
                  <v-row
                    no-gutters
                    v-if="comment.showOuterCommentBox"
                    class="mt-3"
                  >
                    <v-col
                      cols="11"
                      class="reply-textbox-paddingLeft d-flex flex-column"
                    >
                      <div class="d-flex" style="width: 100%">
                        <v-avatar
                          class="marginRight-6"
                          justify="center"
                          align="center"
                          size="40"
                        >
                          <v-img
                            v-if="profileImg === null"
                            src="@/assets/defaultprofile.png"
                          ></v-img>
                          <v-img v-else :src="profileImg"></v-img>
                        </v-avatar>
                        <div class="d-flex" style="width: 100%">
                          <v-text-field
                            :placeholder="$t('writeareply')"
                            v-model="comment.tempReplyMsg"
                            @input="checkCommentLength(comment.tempReplyMsg, 3)"
                            class="comment-textbox"
                            dense
                            hide-details="auto"
                            maxlength="100"
                          ></v-text-field>
                        </div>
                      </div>
                      <div class="mt-3 d-flex justify-end" style="width: 100%">
                        <v-btn
                          depressed
                          text
                          outlined
                          class="text-capitalize ml-5"
                          style="border-radius: 8px; font-size: 14px; width: 80px; 
                          height: 35px;margin-left: 5px"
                          @click="
                            (comment.tempReplyMsg = ''),
                              (mainComNull = true),
                              (comment.showOuterCommentBox = false)
                          "
                        >
                          {{ $t("cancel") }}
                        </v-btn>
                        <v-btn
                          depressed
                          :disabled="replyCommentNull"
                          class="text-capitalize btn_hover_effect ml-5"
                          style="border-radius: 8px; font-size: 14px; width: 80px;
                           height: 35px; color: #ffff; 
                          padding: 0;"
                         
                          color="#A6CC39"
                          @click="AddReplyToComment(comment, null)"
                        >
                          {{ $t("reply") }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- replies of each related comment -->
                  <v-row
                    class="mt-1 comment-paddingLeft4"
                    v-for="(repcomment, repIndex) in comment.replyCommentLists"
                    :key="repIndex"
                    v-show="comment.replyFlag"
                  >
                    <v-col
                      cols="11"
                      md="11"
                      class="reply-textbox-paddingLeft d-flex flex-column pr-0"
                    >
                      <div class="d-flex" style="width: 100%">
                        <v-avatar
                          class="marginRight-6"
                          justify="center"
                          align="center"
                          size="35"
                        >
                          <v-img
                            v-if="repcomment.profileImage"
                            :src="repcomment.profileImage"
                          ></v-img>
                          <v-img
                            v-else
                            src="@/assets/defaultprofile.png"
                          ></v-img>
                        </v-avatar>
                        <div class="d-flex flex-column" style="width: 100%">
                          <p class="mb-0">
                            {{ repcomment.fullname }}
                            {{ repcomment.commentedTime }}
                          </p>
                          <div
                            v-if="!repcomment.showInside_ReplyBox"
                            style="display: flex; align-items: center"
                          >
                            <p
                              style="color: #34b4eb; margin-right: 10px"
                              class="mb-0"
                            >
                              {{ repcomment.replyFullname }}
                            </p>
                          </div>
                          <div v-if="!repcomment.showEditReplyBox" class="mb-2">
                            {{ repcomment.replyMessage }}
                          </div>
                          <div v-else>
                            <div>
                              <v-text-field
                                :placeholder="$t('writeareply')"
                                v-model="repcomment.tempEditReplyText"
                                @input="
                                  checkCommentLength(
                                    repcomment.tempEditReplyText,
                                    4
                                  )
                                "
                                class="comment-textbox"
                                dense
                                hide-details="auto"
                                maxlength="100"
                              ></v-text-field>
                            </div>
                            <div
                              class="mt-3 d-flex justify-end"
                              style="width: 100%"
                            >
                              <v-btn
                                depressed
                                text
                                outlined
                                class="text-capitalize"
                                style="border-radius: 8px; font-size: 14px; width: 80px; height: 35px;"
                                @click="
                                  () => {
                                    repcomment.showEditReplyBox = false;
                                    repcomment.tempEditReplyText = '';
                                  }
                                "
                              >
                                {{ $t("cancel") }}
                              </v-btn>
                              <v-btn
                                depressed
                                :disabled="editReplyNull"
                                class="text-capitalize btn_hover_effect ml-5"
                                style="border-radius: 8px; font-size: 14px; width: 80px; 
                                height: 35px; color: #ffff;padding: 0.3rem 0px 0.3rem 0px;"
                                color="#A6CC39"
                                @click="UpdateReplyText(repcomment, comment)"
                              >
                                {{ $t("update") }}
                              </v-btn>
                            </div>
                          </div>
                          <div>
                            <v-btn
                              style="
                                font-size: 14px;
                                min-width: 54px;
                                min-height: 22px;
                                height: auto !important;
                                justify-content: center;
                                background-color: #f0f0f0;
                                opacity: 0.42;
                                color: #424242;
                                opacity: 1;
                                border-radius: 4px;
                                padding: 0;
                              "
                              text
                              class="text-capitalize"
                              v-if="!repcomment.showInside_ReplyBox"
                              @click="replyToInnerComment(repcomment)"
                            >
                              {{ $t("reply") }}
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <!-- dot action btns of inner reply comments -->
                    <v-col
                      cols="1"
                      md="1"
                      class="px-0"
                      style="display: flex; justify-content: end"
                      v-if="
                        repcomment.memberID == loginmemberID ||
                        loginmemberID == memberID
                      "
                    >
                      <template>
                        <div class="d-flex" style="margin-top: -5px">
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                text
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                style="min-width: 32px !important"
                              >
                                <v-icon style="color: #424242"
                                  >mdi-dots-vertical</v-icon
                                >
                              </v-btn>
                            </template>
                            <v-list class="d-flex flex-column align-start">
                              <div>
                                <v-list-item
                                  class="list-item-padding"
                                  v-show="loginmemberID == repcomment.memberID"
                                >
                                  <v-btn
                                    color="#424242"
                                    style="
                                      font-size: 16px;
                                      width: 100px;
                                      justify-content: left;
                                    "
                                    text
                                    class="text-capitalize"
                                    @click="
                                      editCommentReply(repcomment, comment)
                                    "
                                  >
                                    <v-icon left
                                      >mdi-square-edit-outline</v-icon
                                    >
                                    {{ $t("edit") }}
                                  </v-btn>
                                </v-list-item>
                                <v-list-item class="list-item-padding">
                                  <v-btn
                                    color="#FF6060"
                                    style="width: 100px; justify-content: left"
                                    text
                                    class="text-capitalize"
                                    @click="prepareDeleteReply(repcomment)"
                                  >
                                    <v-icon left>mdi-delete-outline</v-icon>
                                    {{ $t("delete") }}
                                  </v-btn>
                                </v-list-item>
                              </div>
                            </v-list>
                          </v-menu>
                        </div>
                      </template>
                    </v-col>
                    <!-- inner reply box -->
                    <v-col
                      cols="12"
                      md="12"
                      class="reply-textbox-paddingLeft"
                      v-if="repcomment.showInside_ReplyBox"
                      style="display: flex; justify-content: center"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="11"
                          class="reply-textbox-paddingLeft d-flex flex-column"
                        >
                          <div class="d-flex" style="width: 100%">
                            <v-avatar
                              class="marginRight-6"
                              justify="center"
                              align="center"
                              size="40"
                            >
                              <v-img
                                v-if="profileImg === null"
                                src="@/assets/defaultprofile.png"
                              ></v-img>
                              <v-img v-else :src="profileImg"></v-img>
                            </v-avatar>
                            <div class="d-flex" style="width: 100%">
                              <v-text-field
                                :placeholder="$t('writeareply')"
                                v-model="repcomment.tempCommentReplyMsg"
                                @input="
                                  checkCommentLength(
                                    repcomment.tempCommentReplyMsg,
                                    5
                                  )
                                "
                                class="comment-textbox"
                                dense
                                hide-details="auto"
                                maxlength="100"
                              ></v-text-field>
                            </div>
                          </div>
                          <div
                            class="mt-3 d-flex justify-end"
                            style="width: 100%"
                          >
                            <v-btn
                              depressed
                              text
                              outlined
                              width="80"
                              height="35"
                              class="text-capitalize ml-5"
                              style="border-radius: 8px; margin-left: 5px"
                              @click="closeReplyMessage(repcomment)"
                            >
                              {{ $t("cancel") }}
                            </v-btn>
                            <v-btn
                              depressed
                              :disabled="replyInnerComNull"
                              class="text-capitalize btn_hover_effect ml-5"
                              style="border-radius: 8px; font-size: 14px;
                               width: 80px; height: 35px; color: #ffff;
                                padding: 0;"
                              color="#A6CC39"
                              @click="AddReplyToComment(comment, repcomment)"
                            >
                              {{ $t("reply") }}
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-card>
        </template>
      </v-card>
    </v-dialog>

    <v-dialog width="470" v-model="complete_dialog" persistent>
      <v-card
        style="overflow: hidden !important; border-radius: 16px !important"
      >
        <v-card-title style="font-size: 20px; color: #363636">
          <v-row class="mt-1 mb-1">
            <v-col style="text-align: center">
              <p class="mb-2">{{ $t("confirmcomplete") }}</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions style="justify-content: center">
          <v-btn
            @click="viewComplete()"
            depressed
            color="#A6CC39"
            style="font-size: 16px; border-radius: 8px; width: 150px"
            class="text-capitalize white--text mb-2 mt-2 btn_hover_effect"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="470" v-model="exit_dialog" persistent>
      <v-card style="border-radius: 16px !important">
        <v-card-title style="font-size: 20px; color: #363636">
          <v-row>
            <v-col cols="12" md="10" lg="10" xl="10" class="pr-1 pl-1">
              <p class="mb-2">
                {{ $t("Soundnotfinish") }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="2"
              lg="2"
              xl="2"
              style="text-align: right"
              class="pr-1 pl-1"
            >
              <v-btn
                icon
                @click="cancelExitPlayer()"
                color="#424242"
                class="mt-2"
                style="
                  border-radius: 8px !important;
                  border: 1px solid rgba(66, 66, 66, 0.05);
                  top: -8px;
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-2 mt-2"
            text
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
              font-size: 20px;
            "
            @click="cancelExitPlayer()"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            color="#A6CC39"
            style="font-size: 20px; border-radius: 8px; width: 80px"
            class="text-capitalize white--text mb-2 mt-2 btn_hover_effect"
            @click="exitPlayer()"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="delete_dialog"
      max-width="470px"
      max-height="87px"
      style="border-radius: 15px !important"
    >
      <v-card style="border-radius: 15px !important">
        <v-spacer></v-spacer>
        <v-card-title class="flex justify-center items-center mb-4">
          <p class="mb-0" style="font-size: 20px; font-weight: bold">
            Delete Comment
          </p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closemessageDialog()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-left">
          <p
            v-if="deleteType === 'comment'"
            style="font-size: 20px; color: #363636; opacity: 1"
            class="mb-1"
          >
            Do you want to delete this comment?
          </p>
          <p
            v-else
            style="font-size: 20px; color: #363636; opacity: 1"
            class="mb-1"
          >
            Do you want to delete this reply?
          </p>
        </v-card-text>
        <v-divider class="mb-2" style="opacity: 0.5"></v-divider>
        <v-card-actions class="justify-end" style="padding-bottom: 15px">
          <v-btn
            width="70"
            height="39"
            class="text-capitalize ml-5"
            style="border-radius: 8px; font-size: 14px"
            color="#F0F0F0 "
            @click="closemessageDialog()"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            width="76"
            height="39"
            class="text-capitalize btn_hover_effect ml-5"
            style="
              color: #ffff;
              border: 1px solid #a6cc39;
              border-radius: 8px;
              font-size: 14px;
            "
            color="#A6CC39"
            @click="
              deleteType === 'comment'
                ? DeleteComment(deleteComTemp.commentID)
                : DeleteReply(deleteReplyTemp.replyID)
            "
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import WaveSurfer1 from "wavesurfer.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    viewerFlag: Boolean,
    audioName: String,
    // Addfav: Boolean,
    audioDescription: String,
    audioImg: String,
    audioFile: String,
    contentid: Number,
    currentminutes: String,
    requestflag: Boolean,
    playListOpt: Object,
    memberID: String,
  },
  data() {
    return {
      showAlertMessage: false,
      //viewerFlag: false,
      //...comment related......
      deleteReplyTemp: null,
      deleteComTemp: null,
      deleteType: null,
      delete_dialog: false,
      loginmemberID: localStorage.getItem("memberID"),
      new_comment: "",
      commentItems: [],
      commentNull: true,
      replyCommentNull: true,
      mainComNull: true,
      editReplyNull: true,
      replyInnerComNull: true,
      profileImg: localStorage.getItem("profileimage"),
      //......................
      nextPrevFlag: false,
      disablePrevious: true,
      disableNext: true,
      showMp3Player: false,
      power: 0,
      startLoadingAudio: false,
      currentAudioTime: "0:00",
      audioDuration: "0:00",
      showPlay: false,
      wavesurfer: null,
      wavesurferOptions: {
        container: "#waveform",
        waveColor: "#eee", //#D2EDD4
        progressColor: "#A6CC39", //#46B54D
        cursorColor: "#424242",
        barHeight: 1,
        barRadius: 3,
        barWidth: 3,
        height: 95,
        responsive: true,
        origin: "*",
        backend: "MediaElement",
      },

      // AddReport
      progress_time: 0,
      actualViewTime: 0,
      intervalId: 0,
      // pausedTime: 0,
      //isNewlyPlayed: true,
      // currentTimeMap: {},
      complete_dialog: false,
      confirmDialog: false,
      exit_dialog: false,
      Addfav: false,
      maxlength: 30,
    };
  },
  watch: {
    playListOpt(obj) {
      this.disablePrevious = obj.previous === null;
      this.disableNext = obj.next === null;
    },
    audioFile(val) {
      setTimeout(() => {
        if (val) {
          this.PlayMP3(val);
        }
      }, 100);
    },
  },
  computed: {
    heartIcon() {
      return this.Addfav === true ? "mdi-heart" : "mdi-heart-outline";
    },
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  methods: {
    //.......comment related..............................
    async DeleteReply(ID) {
      let self = this;
      await axios
        .post(`${self.web_url}Comment/DeleteReplyComment?replyID=` + ID)
        .then(function (res) {
          if (res.data.status == 0) {
            self.delete_dialog = false;
            self.GetCommentsForSound(self.contentid);
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    async DeleteComment(ID) {
      let self = this;
      await axios
        .post(`${self.web_url}Comment/DeleteComment?CommentID=` + ID)
        .then(function (res) {
          if (res.data.status == 0) {
            self.delete_dialog = false;
            self.deleteComTemp = null;
            self.GetCommentsForSound(self.contentid);
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    async UpdateReplyText(reply, comment) {
      let self = this;
      self.editReplyNull = true;
      const request = {
        replyID: reply.replyID,
        replyMessage: reply.tempEditReplyText,
      };
      axios
        .post(`${self.web_url}Comment/UpdateReplyComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            comment.replyFlag = false;
            self.GetCommentsForSound(self.contentid);
          }
          self.commentNull = false;
        });
    },
    AddReplyToComment(item, reply) {
      let self = this;
      self.replyCommentNull = true;
      self.replyInnerComNull = true;
      let tempReplyID = reply?.replyID || null;
      if (reply != null && reply.memberID == self.loginmemberID) {
        tempReplyID = null;
      }

      let tempReply_msg = reply?.tempCommentReplyMsg || "";
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        commentID: item.commentID,
        replyMessage: reply != null ? tempReply_msg : item.tempReplyMsg, //self.replyMessage,
        replyID: tempReplyID,
      };
      axios
        .post(`${self.web_url}Comment/AddReplyComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetCommentsForSound(self.contentid);
          }
          self.commentNull = false;
        });
    },
    async UpdateComment(update_value) {
      let self = this;
      self.commentNull = true;
      const request = {
        commentID: update_value.commentID,
        commentMessage: update_value.tempComment,
      };
      axios
        .post(`${self.web_url}Comment/UpdateComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetCommentsForSound(self.contentid);
          }
        });
    },
    AddNewComment() {
      let self = this;
      self.mainComNull = true;
      const request = {
        companyID: localStorage.getItem("companyID"),
        userID: parseInt(localStorage.getItem("UserID")),
        videoID: self.contentid,
        updateBy: null,
        createdBy: localStorage.getItem("memberID"),
        commentMessage: self.new_comment,
        Page: 4, //for sound page comment
      };
      axios
        .post(`${self.web_url}Comment/AddComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetCommentsForSound(self.contentid);
          }
        });
      self.new_comment = "";
    },
    prepareDeleteReply(repcomment) {
      let self = this;
      self.deleteType = "reply";
      self.delete_dialog = true;
      self.deleteReplyTemp = repcomment;
    },
    closemessageDialog() {
      this.delete_dialog = false;
    },
    async prepareDeleteComment(comment) {
      let self = this;
      self.delete_dialog = true;
      self.deleteComTemp = comment;
      self.deleteType = "comment";
    },
    closeReplyMessage(item) {
      item.showInside_ReplyBox = false;
      item.tempCommentReplyMsg = "";
      this.commentNull = false;
    },
    replyToInnerComment(repcomment) {
      let self = this;
      self.commentItems.forEach((element) => {
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = "");
        });
      });
      repcomment.showInside_ReplyBox = true; //item.showOuterCommentBox = true
      self.replyInnerComNull = true;
      //self.showUpdateBtnFlag = false;
    },
    editCommentReply(repcomment, comment) {
      let self = this;

      self.commentItems.forEach((element) => {
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = "");
        });
      });

      self.commentItems[comment.no - 1].replyFlag = true;
      self.commentItems[comment.no - 1].replyCommentLists[
        repcomment.no - 1
      ].showEditReplyBox = true;
      self.commentItems[comment.no - 1].replyCommentLists[
        repcomment.no - 1
      ].tempEditReplyText = repcomment.replyMessage;
      self.editReplyNull = repcomment.replyMessage.length === 0;

      //self.showUpdateBtnFlag = true;
    },
    cancelComment(com) {
      //let self = this;
      com.editCommentFlag = false;
      //self.showUpdateBtnFlag = false;
      //self.commentNull = false;
    },
    ClickCommentReply(comment) {
      let self = this;
      self.commentItems.forEach((element) => {
        element.editCommentFlag = false;
        element.replyFlag = false;
        element.showOuterCommentBox = false;
        element.tempReplyMsg = "";
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = ""),
            (item.tempCommentReplyMsg = "");
        });
      });

      //comment.showOuterCommentBox = true;
      self.commentItems[comment.no - 1].showOuterCommentBox = true;
      self.replyCommentNull = true;
    },
    editComment(com) {
      let self = this;
      self.commentItems.forEach((element) => {
        element.editCommentFlag = false;
        element.replyFlag = false;
        element.showOuterCommentBox = false;
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = ""),
            (item.tempCommentReplyMsg = "");
        });
      });

      self.commentItems[com.no - 1].editCommentFlag = true;
      self.commentItems[com.no - 1].tempComment = com.commentMessage;
      self.commentNull = com.commentMessage.length === 0;
      com.editCommentFlag = true;

      //self.updateData = com; //?
      // self.replyMessage = com.commentMessage;
      //self.showUpdateBtnFlag = true;
    },
    showReplylist(comment) {
      comment.replyFlag = !comment.replyFlag;
    },
    checkCommentLength(v, type) {
      if (v.length > 99) {
        alert("Message should be no more than 100 characters");
      }
      switch (type) {
        case 1:
          this.mainComNull = v.length === 0;
          break;
        case 2:
          this.commentNull = v.length === 0;
          break;
        case 3:
          this.replyCommentNull = v.length === 0;
          break;
        case 4:
          this.editReplyNull = v.length === 0;
          break;
        case 5:
          this.replyInnerComNull = v.length === 0;
          break;
      }
    },
    async GetCommentsForSound(contentID) {
      let self = this;
      self.new_comment = "";
      await this.$axios
        .get(
          `${self.web_url}Comment/GetComments?VideoID=${contentID}&Page=${4}`
        )
        .then(function (res) {
          console.log("all comments ", res.data.data);
          if (res.data.data.length > 0) {
            self.commentItems = res.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              replyFlag: false,
              editCommentFlag: false,
              showOuterCommentBox: false,
              showOuterReplyBox: false,
              tempComment: "",
              tempReplyMsg: "",
              replyCommentLists: v.replyCommentLists.map((j, k) => ({
                ...j,
                no: k + 1,
                tempEditReplyText: "",
                tempCommentReplyMsg: "",
                showEditReplyBox: false,
                showInside_ReplyBox: false,
              })),
            }));
          } else {
            self.commentItems = [];
          }
        });
    },
    //............................... end of comment related..........................
    playPreviousSound() {
      this.nextPrevFlag = true;
      this.commentItems = [];
      this.$emit("skipToPrevious", this.playListOpt);
    },
    playNextSound() {
      this.nextPrevFlag = true;
      this.commentItems = [];
      this.$emit("skipToNext", this.playListOpt);
    },
    Readnoti(notificationid, pageid) {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        notificationID: notificationid,
        notiPage: pageid,
      };
      axios
        .post(`${self.web_url}Notification/AddUserIDinNotiJoin`, request)
        .then(function (result) {
          if (result.data.status == 0) {
            self.getNotificationCount();
          }
        });
    },
    async getNotificationCount() {
      let self = this;
      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };
      await axios
        .post(
          `${self.web_urlV3}Notification/GetAllNotificationMobileV4`,
          request
        )
        .then(function (result) {
          if (result.data.status == 0) {
            let d1 = result.data.data.filter(
              (x) => x.viewOrNot === false
            ).length;
            let d2 = result.data.data1.filter(
              (x) => x.newNoti === false
            ).length;
            let d3 = result.data.data2.filter(
              (x) => x.newNoti === false
            ).length;
            let d4 = result.data.data4.filter(
              (x) => x.newNoti === false
            ).length;
            let d5 = result.data.data5.filter(
              (x) => x.newNoti === false
            ).length;
            let d6 = result.data.data6.filter(
              (x) => x.newNoti === false
            ).length;
            let d7 = result.data.data7.filter(
              (x) => x.newNoti === false
            ).length;
            let d8 = result.data.data8.filter(
              (x) => x.newNoti === false
            ).length;
            let d9 = result.data.data3.filter(
              (x) => x.newNoti === false
            ).length;
            let notiCount = d1 + d2 + d3 + d4 + d5 + d6 + d7 + d8 + d9;
            self.$store.commit("savenotiCount", notiCount);
          }
        });
    },
    getFavorite(v) {
      this.Addfav = v;
    },
    cutText() {
      if (this.audioName.length <= this.maxlength) {
        return this.audioName;
      } else {
        return this.audioName.substring(0, this.maxlength) + "...";
      }
    },
    async closeSoundDialog() {
      this.exit_dialog = true;
      this.wavesurfer.pause();
    },
    PlayMP3(item) {
      let self = this;
      const viewerFlag = self.viewerFlag;

      if (self.wavesurfer != null) {
        self.wavesurfer.destroy();
        self.audioDuration = "0:00";
        self.currentAudioTime = "0:00";
        self.showMp3Player = false;
      }

      // Load audio waveform
      self.wavesurfer = WaveSurfer1.create(self.wavesurferOptions);
      self.wavesurfer.load(item);
      self.startLoadingAudio = true;

      // Initialize flags
      self.currentPosition = 0;
      self.userSeeking = false;
      self.alertVisible = false;
      self.audioPlayed = false;

      // Check if the audio was previously played
      const previousPlayedStatus = localStorage.getItem(`audioPlayed_${item}`);
      if (previousPlayedStatus === "true") {
        self.audioPlayed = true;
      }

      self.wavesurfer.on("ready", () => {
        const desiredTime = self.currentminutes || "0";
        const position = self.nextPrevFlag
          ? 0
          : desiredTime / self.wavesurfer.getDuration();
        self.wavesurfer.seekTo(position);
        self.wavesurfer.play();

        self.audioDuration = self.formatTimeNew(self.wavesurfer.getDuration());
      });

      self.wavesurfer.on("loading", (percent) => {
        self.power = percent;

        if (percent === 100) {
          self.playAudio();
          self.startLoadingAudio = false;
          self.showMp3Player = true;
        }
      });

      self.wavesurfer.on("audioprocess", () => {
        self.currentAudioTime = self.formatTimeNew(
          self.wavesurfer.getCurrentTime()
        );
        self.currentPosition = self.wavesurfer.getCurrentTime();
      });

      // Handle user seeking to prevent skipping
      let seekTimeout;
      const handleUserSeeking = () => {
        self.userSeeking = true;
        clearTimeout(seekTimeout);
        seekTimeout = setTimeout(() => {
          self.userSeeking = false;
        }, 100);
      };

      self.wavesurfer.drawer.container.addEventListener(
        "mousedown",
        handleUserSeeking
      );
      self.wavesurfer.drawer.container.addEventListener(
        "mouseup",
        handleUserSeeking
      );

      self.wavesurfer.on("seek", (progress) => {
        if (!viewerFlag && self.userSeeking) {
          const newPosition = progress * self.wavesurfer.getDuration();
          if (Math.abs(newPosition - self.currentPosition) > 1) {
            self.wavesurfer.seekTo(
              self.currentPosition / self.wavesurfer.getDuration()
            );

            if (!self.alertVisible) {
              self.showAlertMessage = true;
              self.alertVisible = true;
            }
          }
        }
      });

      self.wavesurfer.on("play", () => {
        self.intervalId = setInterval(() => {
          self.actualViewTime += 1;

          if (self.actualViewTime >= self.wavesurfer.getDuration()) {
            clearInterval(self.intervalId);
            self.audioPlayed = true;
            localStorage.setItem(`audioPlayed_${item}`, "true");

            if (self.alertVisible) {
              self.showAlertMessage = false;
              self.alertVisible = false;
            }
          }
        }, 1000);
      });

      self.wavesurfer.on("pause", () => {
        clearInterval(self.intervalId);
      });

      self.wavesurfer.on("finish", () => {
        self.showPlay = true;
        self.complete_dialog = true;
        self.audioPlayed = true;
        localStorage.setItem(`audioPlayed_${item}`, "true");

        if (self.alertVisible) {
          self.showAlertMessage = false;
          self.alertVisible = false;
        }
      });

      window.addEventListener("beforeunload", (event) => {
        if (!self.audioPlayed) {
          self.showAlertMessage = true;
          self.alertVisible = true;

          event.preventDefault();
          event.returnValue = "";
        }
      });

      self.wavesurfer.on("destroy", () => {
        if (self.alertVisible) {
          self.showAlertMessage = false;
          self.alertVisible = false;
        }
      });
    },
    clickPlayBtn(value) {
      this.nextPrevFlag = !value;
      console.log("pre/next btn flag ", this.nextPrevFlag);
    },
    async exitPlayer() {
      let self = this;

      self.$emit("updateNotiReadStatusColor"); // to change noti box color after checked.
      let actualview_time = self.actualViewTime.toString();
      const currentTime = self.convertToSeconds(self.currentAudioTime);
      const duration = self.convertToSeconds(self.audioDuration);

      if (self.actualViewTime === 0) {
        self.exit_dialog = false;
        self.showPlay = true;
        self.dialog = false;
      } else {
        let temp = currentTime / duration;
        if (temp.toFixed(1) == "1.0") {
          self.progress_time = parseFloat(temp) - 0.1;
          self.progress_time = self.progress_time.toFixed(1);
        } else {
          self.progress_time = temp.toFixed(1);
        }

        const request = {
          userID: parseInt(localStorage.getItem("UserID")),
          id: self.contentid,
          minutes: currentTime.toString(),
          separatePage: 4,
          progressTime: self.progress_time,
          actualViewTime: actualview_time,
        };

        axios
          .post(`${self.web_url}Reports/AddReportActivity`, request)
          .then((res) => {
            if (res.data.status === 0) {
              self.$emit("updateProgressCircle", request);
              self.exit_dialog = false;
              self.actualViewTime = 0;
              self.showPlay = true;
              self.dialog = false;
            } else {
              console.error("Failed to save user activity report");
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },

    formatTimeNew(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60)
        .toString()
        .padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    formatTime(t_seconds) {
      const minutes = Math.floor(t_seconds / 60);
      const remainingSeconds = (t_seconds % 60).toFixed(1);
      return `${minutes}:${remainingSeconds.padStart(4, "0")}`;
    },
    convertToSeconds(timeString) {
      const [minutes, seconds] = timeString.split(":").map(parseFloat);
      return minutes * 60 + seconds;
    },
    playAudio() {
      this.wavesurfer.play();
      this.showPlay = false;
    },

    pauseAudio() {
      this.wavesurfer.pause();
      this.showPlay = true;
    },

    toggleCheck() {
      this.Addfav = !this.Addfav;
      this.updateFavourite(this.Addfav);
    },
    updateFavourite(isFavourite) {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        contentID: this.contentid,
        contentCheck: 2,
        favFlag: isFavourite,
      };
      axios
        .post(`${this.web_url}Contents/UpdateFavorite`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            if (isFavourite === true) {
              self.$store.commit(
                "savefavCount",
                self.$store.state.library.favCount + 1
              );
            } else {
              self.$store.commit(
                "savefavCount",
                self.$store.state.library.favCount - 1
              );
            }
          }
        });
    },
    viewComplete() {
      let self = this;

      self.$emit("updateNotiReadStatusColor"); // to change noti box color after checked.
      // Stop the view time tracking timer
      let actualview_time = self.actualViewTime.toString();
      const [minutes, seconds] = self.currentAudioTime
        .split(":")
        .map(parseFloat);
      const minute_data = minutes * 60 + seconds;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        id: self.contentid,
        minutes: minute_data.toString(),
        separatePage: 4,
        progressTime: "1",
        actualViewTime: actualview_time,
      };

      axios
        .post(`${self.web_url}Reports/AddReportActivity`, request)
        .then((res) => {
          if (res.data.status === 0) {
            self.$emit("updateProgressCircle", request);
            self.complete_dialog = false;
            self.exit_dialog = false;
            self.dialog = false;
            self.actualViewTime = 0;
          } else {
            console.error("Failed to save user activity report");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    cancelExitPlayer() {
      this.exit_dialog = false;
      this.dialog = true;
      this.showPlay = false;
      this.$emit("show_sound");
      this.wavesurfer.play();
    },
  },
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  padding: unset;
}
.play-back-btn {
  background-color: white !important;
  border-radius: 8px;
  width: 37px;
  min-width: 37px !important;
  height: 37px !important;
  padding-left: 7px !important;
  margin-right: 1rem !important;
}
.play-next-btn {
  background-color: white !important;
  border-radius: 8px;
  width: 37px;
  min-width: 37px !important;
  height: 37px !important;
  padding-left: 8px !important;
  margin-left: 0.5rem !important;
}
.test {
  display: flex;
  justify-content: center;
}

.border1 {
  height: 1px;
  width: 95%;
  background-color: #00000027;
}
.lessonimg {
  width: 192px !important;
  height: 130px !important;
  border-radius: 12px;
}
.lessonTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.lessonDes {
  font-size: 14px;
  opacity: 0.6;
}
.lessonIcon {
  margin-left: 20px;
  color: #4fb14e;
  font-size: 40px;
  top: 10px;
  position: absolute;
}
::v-deep .audioPhoto {
  object-fit: cover;
  border-radius: 16px;
}
.btn_hover_effect {
  color: #fff;
  font-size: 14px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover {
  transition: 0.25s;
}
.btn_hover_effect:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
</style>
<!-- comment section css -->
<style scoped>
.audioTitle-font {
  font-size: 28px;
  color: #424242;
}
.comment-paddingLeft4 {
  padding-left: 16px !important;
}
.marginRight-6 {
  margin-right: 24px !important;
}
.reply-textbox-paddingLeft {
  padding-left: 60px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
::v-deep .comment-textbox {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 14px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .v-dialog {
  overflow-x: hidden;
  overflow-y: hidden;
}
.scrollbar-container {
  padding-right: 5px;
  height: auto;
  padding: 0 0 1rem 0;
  /* padding: 0 2.2rem 0 1.5rem; */
}
.video-scrollbar {
  padding: 0 2.4rem 0 1.7rem;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
@media screen and (max-width: 768px) {
  .scrollbar-container {
    padding-right: 5px;
    height: auto;
    padding-bottom: 1rem;
    padding-left: 0px;
  }
  .video-scrollbar {
    padding-bottom: 2rem;
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
}
@media only screen and (max-width: 600px) {
  .audioTitle-font {
    font-size: 16px;
    color: #424242;
  }
  .videoTitle-font {
    font-size: 12px !important;
  }
  .marginRight-6 {
    margin-right: 5px !important;
  }
  .reply-textbox-paddingLeft {
    padding-left: 0px !important;
  }
  .comment-paddingLeft4 {
    padding-left: 10px !important;
  }
  .video-scrollbar {
    padding: 0;
    height: 25vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
}
</style>
